const Medium = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 14.7846C30 18.7588 29.334 21.9824 28.5121 21.9824C27.6903 21.9824 27.0245 18.7596 27.0245 14.7846C27.0245 10.8096 27.6906 7.58679 28.5121 7.58679C29.3337 7.58679 30 10.8093 30 14.7846Z"
        fill="white"
      />
      <path
        d="M26.2035 14.7846C26.2035 19.2213 24.3094 22.8193 21.973 22.8193C19.6365 22.8193 17.7424 19.2213 17.7424 14.7846C17.7424 10.348 19.6362 6.74994 21.9727 6.74994C24.3091 6.74994 26.2032 10.3468 26.2032 14.7846"
        fill="white"
      />
      <path
        d="M16.9218 14.7846C16.9218 19.498 13.1337 23.319 8.46105 23.319C3.78841 23.319 0 19.4972 0 14.7846C0 10.0721 3.78812 6.25 8.46105 6.25C13.134 6.25 16.9218 10.0712 16.9218 14.7846Z"
        fill="white"
      />
    </svg>
  );
};

export default Medium;

import { TNav } from "../types";

const navs: TNav[] = [
  {
    header: "My Personal Projects",
    link: "projects",
    description:
      "Explore my array of personal projects, each a small but invaluable learning experience in my journey as a software engineer & serving as a testament to my passion for continuous learning and growth. These ventures, ranging from web applications to experimental code snippets, serve as laboratories where I refine my skills and explore new technologies.",
  },
  {
    header: "My Travel Stories",
    link: "travel",
    description:
      "Embark on a visual and narrative journey through my travel adventures, where every picture and anecdote captures the awe-inspiring wonders of the world. From wandering ancient streets to marveling at natural wonders, I invite you to join me in experiencing the profound beauty and diversity of our planet through my eyes. Prepare to be amazed and inspired by the breathtaking landscapes and rich cultures that await around every corner.",
  },
];

export default navs;

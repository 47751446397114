import { Github, Instagram, LinkedIn, Mail, Medium } from "../assets";

const socials = [
  {
    type: "email",
    link: "mailto:megholova@gmail.com",
    Icon: Mail,
  },
  {
    type: "linkedIn",
    link: "https://www.linkedin.com/in/megholova/",
    Icon: LinkedIn,
  },
  {
    type: "instagram",
    link: "https://www.instagram.com/megholova/",
    Icon: Instagram,
  },
  {
    type: "medium",
    link: "https://medium.com/@megholova",
    Icon: Medium,
  },
  {
    type: "github",
    link: "https://github.com/megholova91",
    Icon: Github,
  },
];

export default socials;

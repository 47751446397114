const Mail = () => {
  return (
    <svg
      width="35"
      height="30"
      viewBox="0 0 35 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.8198 0.990585C34.934 0.88402 35 0.72669 35 0.560859V0.560859C35 0.251105 34.7742 0 34.4957 0H0.782803C0.350473 0 0 0.389811 0 0.870668V0.870668C0 1.12951 0.103543 1.37492 0.282536 1.54034L16.7176 16.7287C17.0757 17.0597 17.5954 17.0583 17.9522 16.7255L34.8198 0.990585ZM1.57892 5.98874C0.951242 5.40867 0 5.90515 0 6.81283V28.9286C0 29.5203 0.431285 30 0.963302 30H34.0367C34.5687 30 35 29.5203 35 28.9286V6.38236C35 5.47238 34.0445 4.97668 33.4177 5.56144L18.0617 19.8863L17.959 19.9821C17.6022 20.3149 17.0825 20.3163 16.7243 19.9853L16.6212 19.89L1.57892 5.98874Z"
        fill="white"
      />
    </svg>
  );
};

export default Mail;

import { TPersonalProject } from "../types";

const projectPage = {
  title: "Good things come in small packages",
  description:
    "Check out this lineup of small projects I've tinkered with over the years to level up my frontend development skills!",
};

const projects: TPersonalProject[] = [
  {
    id: "Restaurant App",
    projectName: "Binge Food Fun (Your BFF food app)",
    projectLink: "https://binge-food-fun.netlify.app/",
    projectSnapshot:
      "https://d33wubrfki0l68.cloudfront.net/66c05c4f64889d0008adfe22/screenshot_2024-08-17-08-16-49-0000.webp",
    description:
      "A food app mock with view page, restaurant menu page and cart page",
    tech: ["React.js", "TailwindCSS", "HTML", "CSS", "JavaScript"],
    sourceCodeLink: "https://github.com/megholova91/food-app",
  },
  {
    id: "To Do App",
    projectName: "To-do App",
    projectLink: "https://megholova-to-do-app.netlify.app/",
    projectSnapshot:
      "https://app.netlify.com/.netlify/images?url=https://d33wubrfki0l68.cloudfront.net/6469080c7f935b0008d1cddd/screenshot_2023-05-20-17-49-11-0000.png&fit=cover&h=500&w=800",
    description:
      "This is a basic to-do app where we can add list items and mark them as done.After they are marked as done, they move to a done section from which we can untick and bring them back to the to do section.",
    tech: ["HTML", "CSS", "JavaScript"],
    sourceCodeLink: "https://github.com/megholova91/To-Do-App",
  },
  {
    id: "Doctor App",
    projectName: "Doctor's appointment booking",
    projectLink: "https://megholova-doctor-appointment-app.netlify.app/",
    projectSnapshot:
      "https://app.netlify.com/.netlify/images?url=https://d33wubrfki0l68.cloudfront.net/64690a28e3ef8f00080152c9/screenshot_2023-05-20-17-58-51-0000.png&fit=cover&h=500&w=800",
    description:
      "This is a small app where you will see a list of doctors. On selecting a doctor you will be taken to a page where you can see the available slots for the doctor. On selecting a slot, you can proceed with booking by typing your name.",
    tech: ["HTML", "SCSS", "JavaScript", "TypeScript", "React.js"],
    sourceCodeLink:
      "https://github.com/megholova91/Doctor-appointment-booking-app",
  },
  {
    id: "Vanilla JS Puzzles",
    projectName: "Vanilla JS Puzzles",
    projectLink: "https://js-puzzles.netlify.app/",
    projectSnapshot:
      "https://app.netlify.com/.netlify/images?url=https://d33wubrfki0l68.cloudfront.net/664267eda8fdab0008721b4f/screenshot_2024-05-13-19-20-36-0000.webp&fit=cover&h=500&w=800",
    description:
      "Some fun games and UI components build using HTML, CSS and vanilla JS.",
    tech: ["HTML", "CSS", "JavaScript"],
    sourceCodeLink: "https://github.com/megholova91/vanilla-js-puzzles",
  },
];

export { projects, projectPage };

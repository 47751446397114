const LinkedIn = () => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_192)">
        <path
          d="M28.6026 0H2.27972C1.6751 0 1.09524 0.233322 0.667714 0.648637C0.240184 1.06395 0 1.62724 0 2.21458V27.7854C0 28.3728 0.240184 28.9361 0.667714 29.3514C1.09524 29.7667 1.6751 30 2.27972 30H28.6026C29.2073 30 29.7871 29.7667 30.2146 29.3514C30.6422 28.9361 30.8824 28.3728 30.8824 27.7854V2.21458C30.8824 1.62724 30.6422 1.06395 30.2146 0.648637C29.7871 0.233322 29.2073 0 28.6026 0ZM9.20466 25.5562H4.56158V11.2292H9.20466V25.5562ZM6.8799 9.24375C6.35323 9.24087 5.83923 9.08649 5.40278 8.80011C4.96634 8.51372 4.627 8.10816 4.4276 7.63461C4.2282 7.16106 4.17767 6.64074 4.28239 6.13932C4.38711 5.6379 4.64239 5.17785 5.01601 4.81723C5.38963 4.45661 5.86484 4.21158 6.38167 4.11308C6.8985 4.01457 7.43379 4.06699 7.91998 4.26373C8.40617 4.46047 8.82147 4.79271 9.11347 5.21852C9.40547 5.64432 9.56108 6.14461 9.56066 6.65625C9.56563 6.99879 9.49956 7.3388 9.36638 7.65601C9.2332 7.97321 9.03564 8.26113 8.78548 8.50258C8.53532 8.74404 8.23767 8.9341 7.91029 9.06144C7.5829 9.18877 7.23248 9.25078 6.8799 9.24375ZM26.3186 25.5687H21.6777V17.7417C21.6777 15.4333 20.6676 14.7208 19.3637 14.7208C17.9868 14.7208 16.6357 15.7292 16.6357 17.8V25.5687H11.9926V11.2396H16.4577V13.225H16.5178C16.966 12.3438 18.5358 10.8375 20.9314 10.8375C23.5221 10.8375 26.3208 12.3313 26.3208 16.7063L26.3186 25.5687Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_192">
          <rect width="30.8824" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedIn;

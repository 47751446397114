import { TAbout } from "../types";

const about: TAbout = {
  intro: "Hi there 👋🏻, I'm Megholova!",
  description:
    "Welcome to my digital realm, where lines of code intertwine with tales of adventure and exploration. I am a software engineer by trade, sculpting digital landscapes with JavaScript, React.js, and TypeScript for over {{yearsOfExperience}} vibrant years. Yet, beyond the confines of my workspace, I am a relentless wanderer, driven by an insatiable curiosity to discover the world beyond screens. Welcome to the intersection of innovation and adventure.",
  travelLinkText: "Discover more about my adventures here.",
};

export default about;

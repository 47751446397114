import socials from "../data/socials";

const ContactLinks = () => {
  return (
    <ul className="flex items-center justify-center gap-6">
      {socials.map((social) => {
        const { type, link, Icon } = social;
        return (
          <li key={type}>
            <a href={link} target="_blank">
              <Icon />
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default ContactLinks;

import { Container, createRoot } from "react-dom/client";
import "../index.css";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Projects from "./components/Projects";
import Nav from "./components/Nav";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Travel from "./components/Travel";

const App = () => {
  return (
    <div>
      <Hero />
      <Nav />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "projects",
    element: <Projects />,
  },
  {
    path: "travel",
    element: <Travel />,
  },
]);

const root = createRoot(document.getElementById("root") as Container);
root.render(<RouterProvider router={router} />);

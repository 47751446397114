const Github = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0124 0C6.71094 0 0 6.76041 0 15.124C0 21.8094 4.29993 27.4685 10.2651 29.4714C11.0109 29.622 11.284 29.146 11.284 28.7456C11.284 28.395 11.2595 27.1931 11.2595 25.9409C7.08337 26.8425 6.21374 24.138 6.21374 24.138C5.54261 22.3853 4.54822 21.9348 4.54822 21.9348C3.18139 21.0083 4.64778 21.0083 4.64778 21.0083C6.16396 21.1085 6.95953 22.5607 6.95953 22.5607C8.30148 24.8642 10.4639 24.2133 11.3338 23.8126C11.458 22.836 11.8559 22.16 12.2784 21.7845C8.94771 21.4339 5.44336 20.1319 5.44336 14.3225C5.44336 12.6699 6.0395 11.3178 6.98412 10.2663C6.83508 9.89079 6.31299 8.33804 7.13346 6.25983C7.13346 6.25983 8.40104 5.85912 11.2592 7.81227C12.4828 7.48121 13.7448 7.3128 15.0124 7.31138C16.28 7.31138 17.5721 7.48685 18.7654 7.81227C21.6238 5.85912 22.8914 6.25983 22.8914 6.25983C23.7118 8.33804 23.1894 9.89079 23.0404 10.2663C24.0099 11.3178 24.5815 12.6699 24.5815 14.3225C24.5815 20.1319 21.0771 21.4087 17.7215 21.7845C18.2685 22.2602 18.7405 23.1615 18.7405 24.5888C18.7405 26.617 18.7159 28.2447 18.7159 28.7453C18.7159 29.146 18.9894 29.622 19.7349 29.4717C25.7 27.4682 29.9999 21.8094 29.9999 15.124C30.0245 6.76041 23.289 0 15.0124 0Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_208">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Github;

import ContactLinks from "./ContactLinks";

const Footer = () => {
  return (
    <div className="mt-10 rounded h-20 flex justify-center items-center bg-feather">
      <ContactLinks />
    </div>
  );
};

export default Footer;
